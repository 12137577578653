export const PROJECTS = [
  {
    id: 0,
    title: "SaunaSwap",
    description: "asdasdasdasd asd asd asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg  gs asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg rgar afdg adfgsdhth yhjsfga agh drga rg gf gs",
    imgUrl: "assets/images/spotify-logo.jpg",
    projectUrl: "https://saunaswap.herokuapp.com/saunas",
    repoUrl: "https://github.com/Johanlynggaard/saunaswap"
  }, {
    id: 1,
    title: "AssetBase",
    description: "asdasdasdasd asd asd asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg  gs asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg rgar afdg adfgsdhth yhjsfga agh drga rg gf gs",
    imgUrl: "assets/images/spotify-logo.jpg",
    projectUrl: "https://github.com/Carlos-Diaz-07",
    repoUrl: "https://github.com/Carlos-Diaz-07"
  }, {
    id: 2,
    title: "SaunaSwap",
    description: "asdasdasdasd asd asd asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg  gs asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg rgar afdg adfgsdhth yhjsfga agh drga rg gf gs",
    imgUrl: "assets/images/spotify-logo.jpg",
    projectUrl: "https://github.com/Carlos-Diaz-07",
    repoUrl: "https://github.com/Carlos-Diaz-07"
  }, {
    id: 3,
    title: "AssetBase",
    description: "asdasdasdasd asd asd asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg  gs asddsa a asd a add weda adsf af ad cxva seaf ahgr cdva fdg rgar afdg adfgsdhth yhjsfga agh drga rg gf gs",
    imgUrl: "assets/images/spotify-logo.jpg",
    projectUrl: "https://github.com/Carlos-Diaz-07",
    repoUrl: "https://github.com/Carlos-Diaz-07"
  }
]
